import React from 'react';

import sceneHouse from './sceneHouse';

class House extends React.Component {
    state = {
        isLoaderVisible: true,
        isDorooma: false,
        type: 0,
        dataFloor: null,
        dataSection: null,
        dataFlat: null,
    };

    async componentDidMount() {
        // Загружаем геометрию квартиры в сцену, после чего применяем шаблон ремонта
        this.scene = new sceneHouse({
            onCompleted: this._onCompleted,
            onStartDorooma: this._onStartDorooma,
            onChangeFloor: this._onChangeFloor,
            onChangeSection: this._onChangeSection,
            onChangeFlat: this._onChangeFlat,
        });
    }

    _onCompleted = () => {
        this.setState({ isLoaderVisible: false });
    };

    _onChangeFloor = dataFloor => {
        this.setState({ dataFloor, type: 1 });
    };

    _onChangeSection = dataSection => {
        this.setState({ dataSection, type: 2 });
    };

    _onChangeFlat = dataFlat => {
        this.setState({ dataFlat, type: 3 });
    };

    componentWillUnmount() {
        if (this.scene) {
            //this.scene.removeScene();
            this.scene = null;
        }
    }

    _onClose = () => {
      this.props.onClose();
    };

    _onStartDorooma = () => {
        this.setState({ isDorooma: true });
    };

    _onCloseDorooma = () => {
        this.setState({ isDorooma: false });
    };

    _setType = type => {
        this.scene.setType(type);
        this.setState({ type });
    };

    render() {
        const { dataFloor, dataSection, dataFlat, type } = this.state;
        return <div className='house'>
            <div className='close' onClick={this._onClose}>&#215;</div>

            <div id="canvas-home" />

            <div className='navigation'>
                <div onClick={() => this._setType(0)} className='nav-item'>Дом</div>
                { type > 0 && dataFloor && <div onClick={() => this._setType(1)} className='nav-item'>{dataFloor} этаж</div> }
                { type > 1 && dataSection && <div onClick={() => this._setType(2)} className='flat-item'>{dataSection} секция</div> }
                { type > 2 && dataFlat && <div className='flat-item'>{dataFlat} квартира</div> }
            </div>

            {
                this.state.isDorooma &&
                    <div className='dorooma'>
                        <div className='close' onClick={this._onCloseDorooma}>&#215;</div>
                        <img width="100%" src="room.png" alt="Room"/>
                        {/*<iframe title="dorooma" width="100%" height="100%" allowFullScreen*/}
                        {/*    src="https://dorooma.rubius.com/repair?flatTemplateId=40&companyId=1" />*/}
                    </div>
            }

            <div className='info'>
                <div style={{margin: '5px'}} 
                     className="tab-pane active show" 
                     id="feature-oks-info" role="tabpanel"
                     aria-labelledby="profile-tab">
                    <div className="row infoRow">
                        <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Параметр</div>
                        <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">Значение</div>
                    </div>                   
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Тип:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">Здание</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Кад. номер:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">77:17:0140116:7040</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Кад. квартал:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">77:17:0140116</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Статус:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">Учтенный</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Наименование:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">17-ти этажный индивидуальный жилой дом с*/}
                    {/*        первым нежилым этажем*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Адрес:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">Москва г, п Десеновское, ул Нововатутинская*/}
                    {/*        6-я, д 3, корп 2*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Форма собственности:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">-</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Кадастровая стоимость:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">2 797 613 060,55 руб.</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Общая площадь:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">23 793,0 кв. м</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Основные характеристики:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8"> </div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow infoRow-tab">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">общая этажность:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">18</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow infoRow-tab">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">подземная этажность:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">1</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow infoRow-tab">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">материал стен:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">-</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow infoRow-tab">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">площадь застройки:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">-</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow infoRow-tab">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">завершение строительства:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">2016</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow infoRow-tab">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">ввод в эксплуатацию:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">2015</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Назначение:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">Многоквартирный дом</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Кадастровый инженер:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">Филимонова Светлана Анатольевна</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Дата постановки на учет:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">05.03.2018</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Дата изменения сведений в ГКН:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">05.03.2018</div>*/}
                    {/*</div>*/}
                    {/*<div className="row infoRow">*/}
                    {/*    <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">Дата выгрузки сведений из ГКН:</div>*/}
                    {/*    <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">05.03.2018</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    }
}

export default House;