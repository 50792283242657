import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

import sceneMain from './sceneMain';
//import sceneHouse from './sceneHouse';
import House from './House';

class Home extends React.Component {
    state = {
        isLoaderVisible: true,
        isHouseSelect: false,
    };

    async componentDidMount() {
        // Загружаем геометрию квартиры в сцену, после чего применяем шаблон ремонта
        this.scene = new sceneMain({
            onCompleted: this._onCompleted,
            onHouseSelect: this._onHouseSelect,
        } );
    }

    _onCompleted = () => {
        this.setState({ isLoaderVisible: false });
    };

    _onHouseSelect = () => {
        this.setState({ isHouseSelect: true });
    };

    _onHouseClose = () => {
        this.setState({ isHouseSelect: false });
    };

    componentWillUnmount() {
        if (this.scene) {
            //this.scene.removeScene();
            this.scene = null;
        }
    }

    render() {
        return <div style={{width: '100%', height: '100%'}}>

            {this.state.isLoaderVisible && (
                <div className="global-loader">
                    <MoonLoader
                        sizeUnit={"px"}
                        size={100}
                        color={'#00ffff'}
                    />
                </div>
            )}

            { this.state.isHouseSelect && <House onClose={this._onHouseClose} /> }

            <div id="canvas" />

            {/*<div id="canvas-home" />*/}
        </div>
    }
}

export default Home;