import {
    // Base
    Scene, WebGLRenderer, PerspectiveCamera, Mesh, Vector3, Raycaster, Fog,
    // Constants
    PCFSoftShadowMap, PCFShadowMap, FrontSide,
    // Geometry
    PlaneBufferGeometry,
    // Light
    AmbientLight, DirectionalLight,
    // Materials
    MeshStandardMaterial,
    // Loaders
    TextureLoader,
} from 'three';

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

export default class sceneMain {
    bgColor = 0x99ccff;
    completed = false;
    loader = new FBXLoader();
    houses = [];

    constructor(config = {}) {
        this.onCompleted = config.onCompleted;
        this.onHouseSelect = config.onHouseSelect;

        this._initScene();
        this._buildScene();

        this._renderScene();
    }

    _initCanvas() {
        // Положение и размер сцены
        this.canvas = document.getElementById('canvas');
        const rect = this.canvas.parentElement.getBoundingClientRect();
        this.left = rect.left;
        this.top = rect.top;
        this.width = rect.width;
        this.height = rect.height;
    }

    _initScene() {
        // Первый запуск
        this._initCanvas();

        this.scene = new Scene();
        //this.gui = new GUI();

        this.scene.fog = new Fog(this.bgColor, 8000, 15000);

        // Рендерер
        this.renderer = new WebGLRenderer({ antialias: true, alpha: false });
        this.renderer.setClearColor(this.bgColor, 1);
        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);

        this.renderer.shadowMap.type = PCFSoftShadowMap;
        this.renderer.shadowMap.enabled = true;
        this.renderer.gammaOutput = true;
        this.renderer.gammaFactor = 0.3;

        this.renderer.domElement.style.display = 'block';
        this.canvas.appendChild(this.renderer.domElement);
        window.addEventListener('resize', () => this._onWindowResize(), false);

        // Щелчок по поверхности
        this.canvas.addEventListener('mousedown', e => this._onCanvasMouseDown(e), false);
        this.canvas.addEventListener('touchstart', e => this._onCanvasMouseDown(e), false);
        this.canvas.addEventListener('mouseup', e => this._onCanvasMouseUp(e), false);
        this.canvas.addEventListener('touchend', e => this._onCanvasMouseUp(e), false);

        // Камера
        this.addCamera();

        const light2 = new AmbientLight( 0xffffff, 0.85);
        light2.castShadow = true;
        light2.position.set( 0, 200, 0 );
        this.scene.add( light2 );

        const dirLight = new DirectionalLight( 0xffffff, 0.75);
        dirLight.position.set( 1, 3, 1 );
        dirLight.position.multiplyScalar( 200);
        dirLight.castShadow = true;
        this.scene.add( dirLight );

        const d = 5000;
        dirLight.shadow.camera.left = -d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = -d;
        dirLight.shadow.camera.far = 3500;
        dirLight.shadow.bias = -0.0001;
        dirLight.shadowDarkness = 0.65;

        // Объект луча для определения положения щелчка мыши на сцене
        this.raycaster = new Raycaster();
    }

    /**
     * Изменение размера окна
     * @private
     */
    _onWindowResize() {
        this._initCanvas();
        this.renderer.setSize(this.width, this.height);
        this._renderScene();
    }

    _buildScene() {
        const texture = new TextureLoader().load( "files/10000.png", () => {
            this.completed = true;
            this._renderScene();
            this.onCompleted();
        } );
        this.mesh = new Mesh(
            new PlaneBufferGeometry( 20000, 20000 ),
            new MeshStandardMaterial( {
                color: 0xffffff,
                depthWrite: true,
                map: texture,
                roughness: 1,
                metalness: 0.15,
                emissive: 0x111111,
                side: FrontSide,
                transparent: true
            } )
        );
        this.mesh.receiveShadow = true;
        this.scene.add(this.mesh);

        // Добавление моделей
        const scale = 0.22;
        this.addModel('files/house_1', -750, -170, Math.PI / 3.8, scale);
        this.addModel('files/house_1', -1500, 1100, 0, scale);
        this.addModel('files/house_1', -2200, 800, Math.PI / 4, scale);
        this.addModel('files/house_1', -500, 700, Math.PI / 2, scale);
        this.addModel('files/house_1', 2800, -600, -Math.PI / 2.6, scale);
        this.addModel('files/house_1', 1850, -600, Math.PI / 2, scale);
    }

    addModel(name, x = 0, y = 0, rotate = 0, scale = 1 ) {
        this.loader.load(name,object => {
            object.position.set(x, y, 0);
            object.scale.set(scale, scale, scale);
            object.rotation.x = 0;
            object.rotation.z = rotate;
            this._setObjectColor(object, 0x333399);

            this.houses.push(object);
            this.scene.add( object );
            this._renderScene();
        }, undefined, e => { console.error( e ); });
    }

    /**
     * Добавление перспективной камеры
     */
    addCamera() {
        const d = 3000;
        const resolution = this.width / this.height;
        this.camera = new PerspectiveCamera(45, resolution, 0.1, 20000);
        this.camera.position.x = d;
        this.camera.position.y = -d * 1.5;
        this.camera.position.z = -d * 2;
        this.camera.updateProjectionMatrix();

        this.camera.up = new Vector3(0, 0, 1);
        this.camera.lookAt(this.scene.position);
        this.scene.add(this.camera);

        // Контроль
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableKeys = false;
        this.controls.minDistance = 1000;
        this.controls.maxDistance = 7000;
        this.controls.zoomSpeed = 2;

        // Всегда угол просмотра под 45 градусов
        const baseRotate = Math.PI * 60 / 180;
        this.controls.minPolarAngle = baseRotate - Math.PI / 4;
        this.controls.maxPolarAngle = baseRotate;
        this.controls.target.set(0, 0, 0);
        this.controls.saveState();
        this.controls.update();
        // Перерисовка сцены на изменение камеры
        this.controls.addEventListener('change', this._onChangeCamera);
        this.controls.reset();
    }

    _onChangeCamera = () => {
        this._renderScene();
    };

    /**
     * Щелчок по канве
     * @param e {Event} - собычтие щелчка
     * @private
     */
    _onCanvasMouseDown(e) {
        e.preventDefault();

        if (e.touches) {
            this.startClick = { x: e.touches[0].clientX, y: e.touches[0].clientY }
        } else {
            this.startClick = { x: e.clientX, y: e.clientY };
        }

        //this._renderScene();
    }

    /**
     * Щелчок по канве для определения щелчка по стене
     * @param e {Event} - собычтие щелчка
     * @private
     */
    _onCanvasMouseUp(e) {
        e.preventDefault();
        let endClick;

        if (e.touches) {
            endClick = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
        } else {
            endClick = { x: e.clientX, y: e.clientY };
        }

        // Если был поворот или перемещение
        if (!(this.startClick.x === endClick.x && this.startClick.y === endClick.y)) {
            //this._renderScene();
            return;
        }

        // Определение пересечечения
        const mouse = {
            x: ((endClick.x - this.left) / this.renderer.domElement.clientWidth) * 2 - 1,
            y: -((endClick.y - this.top) / this.renderer.domElement.clientHeight) * 2 + 1,
        };

        this.raycaster.setFromCamera(mouse, this.camera);

        let targetHouse = null;
        let targetDistance = null;
        this.houses.forEach(house => {
            const intersects = this.raycaster.intersectObjects(house.children, true);
            if (intersects.length === 0) return;
            const { distance } = intersects[0];
            if (!targetDistance || (targetDistance && distance < targetDistance)) {
                targetDistance = distance;
                targetHouse = house;
            }
        });

        if (!targetHouse) return;

        this.houses.forEach(house => {
            if (house === targetHouse) {
                this._setObjectColor(targetHouse, 0xff0000);
                this.onHouseSelect();
            }
            else this._setObjectColor(house, 0x333399);
        });

        this._renderScene();
    }

    _setObjectColor(object, color) {
        object.traverse(child => {
            if (!child.isMesh) return;
            child.material = new MeshStandardMaterial( {
                color,
                opacity: 0.9,
                transparent: true,
                roughness: 0.5,
                metalness: 0,
                emissive: 0x000055
            });
            child.castShadow = true;
            child.receiveShadow = true;
        });
    }

    _renderScene() {
        if (!this.completed) return;
        this.renderer.render(this.scene, this.camera);
    }
}