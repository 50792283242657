import {
    // Base
    Scene, WebGLRenderer, PerspectiveCamera, Vector3, Group, Raycaster,
    // Light
    AmbientLight, DirectionalLight,
    // Materials
    MeshStandardMaterial
} from 'three';

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

export default class sceneHouse {

    bgColor = 0x000000;
    completed = false;
    type = 0;
    loader = new FBXLoader();
    floors = [];
    targetFloor = null;

    constructor(config = {}) {
        this.onCompleted = config.onCompleted;
        this.onStartDorooma = config.onStartDorooma;

        this.onChangeFloor = config.onChangeFloor;
        this.onChangeSection = config.onChangeSection;
        this.onChangeFlat = config.onChangeFlat;

        this._initScene();
        this._buildScene();

        this._renderScene();
    }

    _initCanvas() {
        // Положение и размер сцены
        this.canvas = document.getElementById('canvas-home');
        const rect = this.canvas.parentElement.getBoundingClientRect();
        this.left = rect.left + 200;
        this.top = rect.top + 50;
        this.width = rect.width;
        this.height = rect.height;
    }

    _initScene() {
        // Первый запуск
        this._initCanvas();

        this.scene = new Scene();

        // Рендерер
        this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setClearColor(this.bgColor, 0);
        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);

        this.renderer.domElement.style.display = 'block';
        this.canvas.appendChild(this.renderer.domElement);
        window.addEventListener('resize', () => this._onWindowResize(), false);

        // Щелчок по поверхности
        this.canvas.addEventListener('mousedown', e => this._onCanvasMouseDown(e), false);
        this.canvas.addEventListener('touchstart', e => this._onCanvasMouseDown(e), false);
        this.canvas.addEventListener('mouseup', e => this._onCanvasMouseUp(e), false);
        this.canvas.addEventListener('touchend', e => this._onCanvasMouseUp(e), false);
        this.canvas.addEventListener('dblclick', e => this._onCanvasDblClick(e), false);

        // Камера
        this.addCamera();

        const light2 = new AmbientLight( 0xffffff, 0.85);
        light2.castShadow = true;
        light2.position.set( 0, 200, 0 );
        this.scene.add( light2 );

        const dirLight = new DirectionalLight( 0xffffff, 0.75);
        dirLight.position.set( 1, 3, 1 );
        dirLight.position.multiplyScalar( 200);
        dirLight.castShadow = true;
        this.scene.add( dirLight );

        // Объект луча для определения положения щелчка мыши на сцене
        this.raycaster = new Raycaster();
    }

    _onCanvasDblClick() {
        if (this.type === 0) {
            if (!this.floorGroup) return;

            this.floorGroup.visible = false;

            this._loadSection();
            this.type = 1;
        } else
        if (this.type === 1) {
            if (!this.section) return;

            this.section.visible = false;

            this._loadFlats();
            this.type = 2;
        } else
        if (this.type === 2) {
           this.onStartDorooma();
        }
    }

    /**
     * Изменение размера окна
     * @private
     */
    _onWindowResize() {
        this._initCanvas();
        this.renderer.setSize(this.width, this.height);
        this._renderScene();
    }

    _buildScene() {
        const scale  = 1.2;
        const color1 = 0x333399;
        const color2 = 0x337799;
        this.loader.load('files/house_1_floor',object => {
            object.scale.set(scale, scale, scale);
            object.rotation.x = 0;
            object.rotation.z = 0;
            const stepY = 80;

            const n = 17;
            let baseY = -stepY;
            this.floorGroup = new Group();
            for (let i = 0; i < n; i++) {
                let object1 = object.clone(true);
                const color = i % 2 ? color1 : color2;
                object1.userColor = color;
                this._setObjectColor(object1, color);
                object1.position.set(0, 0, baseY);
                object1.userData.number = i + 1;
                this.floors.push(object1);

                this.floorGroup.add(object1);
                baseY += stepY;
            }
            this.scene.add(this.floorGroup);

            this._renderScene();
        }, undefined, e => { console.error( e ); });
    }

    /**
     * Добавление перспективной камеры
     */
    addCamera() {
        const d = 2000;
        const resolution = this.width / this.height;
        this.camera = new PerspectiveCamera(45, resolution, 0.1, 20000);
        this.camera.position.x = d;
        this.camera.position.y = -d * 1.5;
        this.camera.position.z = -d * 2;
        this.camera.updateProjectionMatrix();

        this.camera.up = new Vector3(0, 0, 1);
        this.camera.lookAt(this.scene.position);
        this.scene.add(this.camera);

        // Контроль
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableKeys = false;
        this.controls.minDistance = 2000;
        this.controls.maxDistance = 6000;
        this.controls.zoomSpeed = 2;
        this.controls.enablePan = false;

        // Всегда угол просмотра под 45 градусов
        const baseRotate = Math.PI * 60 / 180;
        this.controls.minPolarAngle = baseRotate;
        this.controls.maxPolarAngle = baseRotate;
        this.controls.target.set(0, 0, 0);
        this.controls.saveState();
        this.controls.update();
        // Перерисовка сцены на изменение камеры
        this.controls.addEventListener('change', this._onChangeCamera);
        this.controls.reset();
    }

    _onChangeCamera = () => {
        this._renderScene();
    };

    /**
     * Щелчок по канве
     * @param e {Event} - собычтие щелчка
     * @private
     */
    _onCanvasMouseDown(e) {
        e.preventDefault();

        if (e.touches) {
            this.startClick = { x: e.touches[0].clientX, y: e.touches[0].clientY }
        } else {
            this.startClick = { x: e.clientX, y: e.clientY };
        }
    }

    /**
     * Щелчок по канве для определения щелчка по стене
     * @param e {Event} - собычтие щелчка
     * @private
     */
    _onCanvasMouseUp(e) {
        e.preventDefault();
        let endClick;

        if (e.touches) {
            endClick = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
        } else {
            endClick = { x: e.clientX, y: e.clientY };
        }

        // Если был поворот или перемещение
        if (!(this.startClick.x === endClick.x && this.startClick.y === endClick.y)) {
            this._renderScene();
            return;
        }

        // Определение пересечечения
        const mouse = {
            x: ((endClick.x - this.left) / this.renderer.domElement.clientWidth) * 2 - 1,
            y: -((endClick.y - this.top) / this.renderer.domElement.clientHeight) * 2 + 1,
        };

        this.raycaster.setFromCamera(mouse, this.camera);

        if (this.type === 0) {
            let targetDistance = null;
            this.floors.forEach(house => {
                const intersects = this.raycaster.intersectObjects(house.children, true);
                if (intersects.length === 0) return;
                const {distance} = intersects[0];
                if (!targetDistance || (targetDistance && distance < targetDistance)) {
                    targetDistance = distance;
                    this.targetFloor = house;
                }
            });

            if (!this.targetFloor) return;

            this.onChangeFloor(this.targetFloor.userData.number);

            this.floors.forEach(floor => {
                if (floor === this.targetFloor) this._setObjectColor(this.targetFloor, 0xff0000);
                else this._setObjectColor(floor, floor.userColor);
            });
        } else
        if (this.type === 1) {
            const intersects = this.raycaster.intersectObjects(this.section.children, true);
            if (intersects.length === 0) return;

            let targetSection = intersects[0].object;

            this.section.children.forEach((section, i) => {
                if (section === targetSection.parent) {
                    this._setObjectColor(targetSection, 0xff0000);
                    this.onChangeSection(i + 1);
                }
                else  this._setObjectColor(section, 0x333399);
            });
        } else
        if (this.type === 2) {
            const intersects = this.raycaster.intersectObjects(this.flats.children, true);
            if (intersects.length === 0) return;

            let targetFlat = intersects[0].object;
            this.flats.children.forEach((flat, i) => {
                if (flat === targetFlat.parent) {
                    this._setObjectColor(targetFlat, 0xff0000);
                    this.onChangeFlat(i + 100);
                }
                else  this._setObjectColor(flat, 0x333399);
            });
        }

        this._renderScene();
    }

    _loadSection() {
        const scale  = 2;
        const color = 0x333399;
        this.loader.load('files/house_1_sections',object => {
            object.scale.set(scale, scale, scale);
            object.rotation.x = Math.PI / 2;
            object.rotation.y = 0;
            object.rotation.z = 0;
            this._setObjectColor(object, color);
            object.position.set(0, 0, 0);
            this.section = object;
            this.scene.add(object);
            this._renderScene();
        }, undefined, e => { console.error( e ); });
    }

    _loadFlats() {
        const scale  = 50;
        const color = 0x333399;
        this.loader.load('files/house_1_flats',object => {
            object.scale.set(scale, scale, scale);
            object.rotation.x = Math.PI / 2;
            object.rotation.y = 0;
            object.rotation.z = 0;
            this._setObjectColor(object, color);
            object.position.set(0, 0, 0);
            this.flats = object;
            this.scene.add(object);
            this._renderScene();
        }, undefined, e => { console.error( e ); });
    }

    _setObjectColor(object, color) {
        object.traverse(child => {
            if (!child.isMesh) return;
            child.material = new MeshStandardMaterial( {
                color,
                opacity: 1,
                transparent: true,
                roughness: 0.5,
                metalness: 0,
                emissive: 0x000055
            });
            child.castShadow = true;
            child.receiveShadow = true;
        });
    }

    setType(type) {
        this.type = type;
        if (type === 0) {
            this.floorGroup && (this.floorGroup.visible = true);
            this.flats && (this.flats.visible = false);
            this.section && (this.section.visible = false);
            this._setObjectColor(this.targetFloor, this.targetFloor.userColor);
        }
        if (type === 1) {
            this.floorGroup && (this.floorGroup.visible = false);
            this.flats && (this.flats.visible = false);
            this.section && (this.section.visible = true);
        }

        this._renderScene();
    }

    _renderScene() {
        //if (!this.completed) return;
        this.renderer.render(this.scene, this.camera);
    }
}

